<template>
  <div class="wrapper py-8">
    <div class="container flex-col privacy-policy mobile:w-full px-1">
      <h1 class="self-center mobile:text-3xl">Privaatsus&shy;poliitika</h1>
      <div class="prg-container">
        <h3>1. Sissejuhatus</h3>
        <p>
          Isikuandmete vastutavaks töötlejaks on Gewodo OÜ (registrikood
          16008050, edaspidi: Gewodo) asukohaga Harju maakond, Tallinn,
          Kesklinna linnaosa, Jõe tn 3, 10151, on isikuandmete vastutav töötleja
          veebilehel www.gewodo.com (edaspidi: portaal).
        </p>
        <p>
          Käesolev privaatsusteade selgitab, mis on isikuandmed, missuguseid
          portaali kasutaja isikuandmeid ja mis alustel Gewodo töötleb,
          missugused on sellega seoses kasutaja õigused ning missuguseid
          meetmeid Gewodo kasutaja isikuandmete kaitsmiseks on võtnud.
        </p>
        <p>
          Gewodo kogub ja töötleb järgmisi andmeid kasutajate kohta (edaspidi:
          isikuandmed):
        </p>
        <ul>
          <li>
            Portaalis registreerimisel Kasutaja poolt avaldatud andmed: ees- ja
            perekonnanimi, isikukood, aadress, telefoninumber, e-posti aadress;
          </li>
          <li>
            Portaali teenuste kasutamisel kogutud andmed: kasutaja
            arveldusarvenumber, tellitud teenuste ja toodete info,
            akrediteeringud/sertifikaadid, tööde teostamise aadress,
            lisateenused, makseinfo, mh teave kasutaja poolt teostatud,
            pooleliolevate ning tühistatud tööde kohta.
          </li>
          <li>
            Muul viisil Portaali kasutamise teel saadud andmed nagu näiteks
            Portaali vahendusel peetav kirjavahetus teiste
            kasutajatega/kasutajatoega, kasutaja tegevuste logi, Portaalis
            esitatud päringud ja otsingud, andmed teenuse kasutamise seadme
            kohta, analüütilised andmed kasutaja poolt Portaalis tehtud
            tehingute kohta.
          </li>
        </ul>
        <p>
          Muul viisil P Isikuandmete avaldamine Gewodole on vabatahtlik, kuid
          võib olla vältimatult vajalik Portaali teenuste osutamiseks. Seega kui
          kasutaja otsustab Gewodole Isikuandmeid mitte avaldada, ei saa Gewodo
          kasutajale teenust osutada.
        </p>
        <p>
          Gewodo eesmärgiks ei ole koguda eriliigilisi isikuandmeid, kuid need
          võivad meile teatavaks saada juhuslikult, näiteks kliendisuhtluse
          raames meile saabunud kirja või telefonikõne kaudu siis, kui klient
          need meile avaldab.
        </p>
        <p>
          Gewodo töötleb kasutaja isikuandmeid ausalt, Euroopa Liidu ja Eesti
          õigusaktidega lubatud viisil ja ulatuses.
        </p>
        <p>
          Gewodo ja tema töötajad rakendavad kasutaja isikuandmete töötlemisel
          kõiki seadusega nõutavaid hoolsus- ja turvameetmeid, tagamaks kasutaja
          isikuandmete üksnes eesmärgipärase töötlemise ja kaitsmaks kasutaja
          isikuandmete tahtmatu või volitamata töötlemise, avalikuks tuleku või
          hävimise eest. Andmeid töödeldakse üksnes eesmärgil, milleks andmeid
          kogutakse.
        </p>
        <p>
          Gewodo võimaldab ligipääsu kasutaja isikuandmetele üksnes piiratud
          arvule oma töötajatele, kes kohustuvad nendega sõlmitud ametisaladuse
          hoidmise kohustust puudutavate kokkulepete alusel hoidma kasutaja
          isikuandmeid konfidentsiaalsena Gewodo organisatsioonis töötamise ajal
          kui ka pärast töösuhte lõppemist. Gewodo ei luba volitamata
          kolmandatele isikutele ligipääsu kasutaja isikuandmetele ja neid
          andmeid kajastavale Gewodo andmebaasile.
        </p>
      </div>
      <div class="prg-container">
        <h3>2. Õiguslik alus</h3>
        <p>
          Gewodo töötleb Portaali kasutajate Isikuandmeid järgmistel õiguslikel
          alustel:
        </p>
        <ul>
          <li>
            Portaali kasutajale teenuse osutamiseks või teenuse osutamise
            tagamiseks;
          </li>
          <li>Portaali kasutaja poolt Gewodole antud nõusoleku alusel;</li>
          <li>Gewodole kohalduva juriidilise kohustuse täitmiseks;</li>
          <li>
            Gewodo õigusliku huvi alusel. Näiteks on Gewodol õigus töödelda
            kasutaja isikuandmeid õigustatud huvi alusel pettuse ennetamiseks
            või oma IT-süsteemide võrguturbe ja teabe turvalisuse tagamiseks.
          </li>
        </ul>
      </div>
      <div class="prg-container">
        <h3>3. Isikuandmete kogumise ja töötlemise õiguslik alus</h3>
        <p>Gewodo kogub ja töötleb isikuandmeid järgmistel eesmärkidel:</p>
        <ul>
          <li>
            kasutajate registreerimiseks platvormil ning nende isikusamasuse
            tuvastamiseks;
          </li>
          <li>teenuse saajate poolt esitatud tellimuste haldamiseks;</li>
          <li>teenuse pakkujatega lepingute sõlmimiseks;</li>
          <li>maksete tegemiseks ja arvete haldamiseks;</li>
          <li>ettevõtte arenduseks vajalike arendustööde läbiviimiseks;</li>
          <li>kasutajate vaheliste vaidluste vahendamiseks;</li>
          <li>
            vajadusel teenuse pakkujate akrediteeringute/sertifikaatide
            kontrollimiseks;
          </li>
          <li>
            kasutajate poolt platvormi kasutajatingimuste rikkumiste
            menetlemiseks;
          </li>
          <li>kasutajakontode sulgemiseks;</li>
          <li>
            tööde tellijate käitumisharjumuste jälgimiseks (sh
            profileerimiseks);
          </li>
          <li>
            tööde teostajate poolt sooritatud tööde kvaliteedi jälgimiseks;
          </li>
          <li>
            kasutajate platvormi kasutusharjumuste jälgimiseks (sh
            profileerimiseks); meeldetuletuste edastamiseks;
          </li>
          <li>turundusmaterjalide edastamiseks;</li>
          <li>
            isiku nõusolekul kasutab platvorm Google ja Facebooki kontol olevat
            põhiteavet (ees- ja perekonnanimi, sünnikuupäev ja e-posti aadress),
            et hõlbustada platvormi kasutamist.
          </li>
        </ul>
        <p>
          Kasutajad kohustuvad Portaali teenuste kasutamisel teatavaks saanud
          teiste kasutajate Isikuandmeid (s.o. nimi, aadress, e-posti aadress,
          telefoninumber) töötlema üksnes kooskõlas käesoleva
          privaatsuspoliitikaga ning kasutajalepinguga.
        </p>
      </div>
      <div class="prg-container">
        <h3>4. Andmesubjekti õigused</h3>
        <p>Andmesubjektil on järgmised õigused:</p>
        <ul>
          <li>
            Õigus taotleda juurdepääsu oma andmetele. Kasutajal in igal ajal
            õigus tutvuda Gewodo kasutuses olevate kasutaja isikuandmetega.
            Reeglina on Gewodo tema kasutuses olevad isikuandmed teinud
            kasutajale portaali vahendusel kättesaadavaks. Kasutajal on siiski
            õigus paluda Gewodol edastada kasutaja kohta kogutud isikuandmeid,
            mille kohta kasutaja ise portaali vahendusel tutvuda ei saa.
          </li>
          <li>
            Õigus nõuda isikuandmete parandamist, kui Gewodo poolt kasutatavad
            kasutaja isikuandmed on ebaõiged.
          </li>
          <li>
            Õigus nõuda Gewodo poolt hoitavate isikuandmete kustutamist, seda
            eelkõige, kui andmeid töödeldakse õigustatud huvi või nõusoleku
            alusel. Isikuandmete kustutamine ei ole võimalik, kui andmete
            töötlemine või säilitamine on vajalik seadusest tuleneva kohustuse
            täitmisega, isikuandmeid on vaja eesmärgi täitmiseks, milleks need
            on kogutud (eelkõige portaali vahendusel sõlmitud lepingute
            täitmiseks). Kui Gewodol ei ole võimalik isikuandmeid kustutada,
            põhjendab ta seda taotluse esitanud kasutajale.
          </li>
          <li>
            Õigus esitada vastuväiteid isikuandmete töötlemise toimingutele, kui
            isikuandmete töötlemine toimub õigustatud huvi alusel (vt eespool).
          </li>
          <li>
            Õigus isikuandmete töötlemise piiramisele, kui
            <ul>
              <li>
                kasutaja on vaidlustanud isikuandmete õigsuse, ajaks kuni Gewodo
                kontrollib selliste andmete õigsust;
              </li>
              <li>
                isikuandmete töötlemine on ebaseaduslik, kuid kasutaja ei soovi
                isikuandmete kustutamist;
              </li>
              <li>
                kasutajal on isikuandmeid vaja õigusnõuete koostamiseks,
                esitamiseks või kaitsmiseks;
              </li>
              <li>
                kasutaja on esitanud vastuväite isikuandmete töötlemise
                protsessile, ajaks kuni kontrollitakse, kas Gewodo õiguspärased
                põhjused kaaluvad üles kasutaja põhjendused.
              </li>
            </ul>
          </li>
        </ul>
        <p>
          Mistahes eeltoodud õiguse teostamiseks võib kasutaja saata Gewodole
          taotluse portaali iseteeninduskeskkonna vahendusel või e-posti
          aadressile info@gewodo.com. Õiguse kasutamisel tuleb seda Gewodole
          edastatavas kirjas selgelt väljendada. Koopia kõigist kasutajat
          puudutavatest isikuandmetest, mis ei ole portaali vahendusel
          kättesaadavad, edastatakse kasutajale 30 päeva jooksul vastava
          taotluse saamisest.
        </p>
        <p>
          Kasutajal on ka õigus pöörduda oma isikuandmete kaitsmise eesmärgil
          järelevalveasutuse poolt (Eestis Andmekaitse Inspektsioon) või kohtu
          poole. Andmekaitse Inspektsiooni poole pöördumiseks saab rohkem
          informatsiooni Andmekaitse Inspektsiooni koduleheküljelt
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.privacy-policy {
  p {
    @apply mb-3;
  }
  h1,
  h2,
  h3 {
    @apply mb-3;
  }
  ul {
    li {
      @apply ml-5 mb-5;
    }
    list-style-type: disc;
  }
}
</style>
